import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { setLoading } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { UserInterest } from '@app/consumer-information/user-interest';
import { AuthService } from '@app/core/auth/auth.service';
import { PermissionService } from '@app/core/auth/permissions.service';
import { Permissions } from '@core/auth/permissions';

import { BuiltInRoleType } from '@shared/built-in-role-type';
import { Constants } from '@shared/constants';
import { ValidationMessage } from '@shared/forms/validation-message/validation-message';
import { dateWithoutDayValidator } from '@shared/forms/validators/date-without-day-validator';
import { Validators } from '@shared/forms/validators/validators';
import { BaseUserInfo } from '@shared/models/base-user-info';
import { PagedEntities } from '@shared/models/paged-entities';
import { UserAdditionalInformation } from '@shared/models/user-additional-info';
import { UserNotificationSettings } from '@shared/models/user-notification-settings';
import { UserProfileInfo } from '@shared/models/user-profile-info';
import { NotificationsService } from '@shared/notifications/services/notifications.service';
import { SnackBarService } from '@shared/snack-bar/snack-bar.service';
import { LookupService } from '@shared/state/lookup/lookup.service';
import { Lookups } from '@shared/state/lookup/lookups';
import { PageRequest, Page } from '@shared/table/page';

import { User } from '../state/user';
import { UserSubscription } from '../state/user-subscription';
import { UsersQuery } from '../state/users.query';
import { UsersStore } from '../state/users.store';
import { HostAccountStatus } from '../user-details-personal-info/host-account-status.enum';
import { UserStatus } from '../user.enum';
import { UserDataservice } from './user.dataservice';

export interface UserSearchQuery {
    searchTerm?: string;
    name?: string;
    email?: string;
    status?: string;
    languages?: Array<string>;
    roleEnum?: BuiltInRoleType;
    zipCode?: string;
    statuses?: Array<UserStatus> | UserStatus;
    createdBy?: string;
    sortBy?: string;
}

@Injectable({ providedIn: 'root' })
export class UserService {
    Permissions: typeof Permissions = Permissions;

    constructor(
        private lookupsService: LookupService,
        private router: Router,
        private snackBar: SnackBarService,
        private translocoService: TranslocoService,
        private userDataService: UserDataservice,
        private usersQuery: UsersQuery,
        private usersStore: UsersStore,
        private formBuilder: FormBuilder,
        private permissionsService: PermissionService,
        private authService: AuthService,
        private notificationsService: NotificationsService
    ) {}

    page(
        request: PageRequest<User>,
        query: UserSearchQuery
    ): Observable<Page<User>> {
        query.statuses = [UserStatus.Active, UserStatus.Inactive];
        return this.userDataService.getUsers(request, query).pipe(
            map((response: PagedEntities<User>) => {
                const entities = response.entities.map((user: User) => ({
                    ...user,
                    name: `${user.firstName} ${user.lastName}`,
                    statusName:
                        user.status == UserStatus.Active
                            ? this.translocoService.translate('Active')
                            : user.status == UserStatus.Inactive
                            ? this.translocoService.translate('Inactive')
                            : this.translocoService.translate('Pending'),
                }));

                this.usersStore.set(entities);

                return {
                    content: entities,
                    size: entities.length,
                    totalElements: response.totalCount,
                    number: request.page,
                } as Page<User>;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to load Users.');
                return EMPTY;
            })
        );
    }

    pageUsersByStatus(
        request: PageRequest<User>,
        query: UserSearchQuery
    ): Observable<Page<User>> {
        return this.userDataService.getUsers(request, query, true).pipe(
            map((response: PagedEntities<User>) => {
                const entities = response.entities.map((user: User) => ({
                    ...user,
                    name: `${user.firstName} ${user.lastName}`,
                }));
                this.usersStore.upsertMany(entities);

                return {
                    content: entities,
                    size: entities.length,
                    totalElements: response.totalCount,
                    number: request.page,
                } as Page<User>;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to load in Users by status.');
                return EMPTY;
            })
        );
    }

    getById(id: string): Observable<User> {
        return this.userDataService.getById(id).pipe(
            map((user: User) => {
                if (user.dateOfBirth) {
                    user.dateOfBirth = moment.utc(
                        user.dateOfBirth,
                        Constants.DATE_FORMAT_ISO
                    );
                }

                this.usersStore.upsert(user.id, user);

                this.usersStore.setActive(user.id);

                return user;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to load User.');
                return EMPTY;
            })
        );
    }

    getUserProfile(): Observable<BaseUserInfo> {
        return this.userDataService.getUserProfile().pipe(
            map((user: BaseUserInfo) => {
                return user;
            }),
            catchError(() => {
                this.snackBar.open('Failed to load User.');
                return EMPTY;
            })
        );
    }

    upsertUserAdditionalInfo(
        additionalInformation: UserAdditionalInformation
    ): Observable<UserAdditionalInformation> {
        return this.userDataService
            .upsertUserAdditionalInfo(additionalInformation)
            .pipe(
                tap((response: UserAdditionalInformation) => {
                    this.usersStore.upsert(additionalInformation.userId, {
                        additionalInformation: response,
                    });

                    this.snackBar.open(
                        this.translocoService.translate('Information updated.')
                    );
                }),
                catchError(() => {
                    this.snackBar.open(
                        this.translocoService.translate(
                            'Failed to update additional information.'
                        )
                    );
                    return EMPTY;
                })
            );
    }

    getUserActiveSubscription({
        id,
        showMessageOnError = true,
    }: {
        id: string;
        showMessageOnError: boolean;
    }): Observable<UserSubscription> {
        return this.userDataService.getUserActiveSubscription(id).pipe(
            filter(Boolean),
            map((userSubscription: UserSubscription) => {
                userSubscription.subscriptionPlan =
                    userSubscription.pricingPlanName +
                    ' ' +
                    userSubscription.subscriptionType;

                this.usersStore.update(id, (user: User) => ({
                    ...user,
                    activeSubscription: userSubscription,
                }));

                return userSubscription;
            }),
            catchError(() => {
                const message = 'Failed to load User Subscription.';
                if (showMessageOnError) {
                    this.snackBar.open(message);
                } else {
                    console.error(message);
                }

                return EMPTY;
            })
        );
    }
    cancelSubscription(id: string): Observable<any> {
        return this.userDataService.cancelSubscription(id).pipe(
            tap(() => {
                this.snackBar.open('Subscription cancelled.');
                return EMPTY;
            }),
            catchError(() => {
                this.snackBar.open('Failed to cancel Subscription.');
                return EMPTY;
            })
        );
    }
    getMyInfo(): Observable<UserProfileInfo> {
        return this.userDataService.getMyInfo().pipe(
            filter(Boolean),
            map((user: UserProfileInfo) => {
                if (user.dateOfBirth) {
                    user.dateOfBirth = moment.utc(
                        user.dateOfBirth,
                        Constants.DATE_FORMAT_ISO
                    );
                }

                this.usersStore.upsert(user.id, user);

                this.usersStore.setActive(user.id);

                return user;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to load User.');
                return EMPTY;
            })
        );
    }

    exists(email: string): Observable<ValidationMessage> {
        const userItem = this.usersQuery.getActiveUser();

        if (userItem && userItem.email.toLowerCase() === email.toLowerCase()) {
            return of(null);
        }

        return this.userDataService.exists(email);
    }

    post(user: User): Observable<User> {
        user.dateOfBirth = this.formatDateOfBirth(user.dateOfBirth);
        return this.userDataService.post(user).pipe(
            map((response) => {
                this.lookupsService.removeLookup(Lookups.GroupAdmins);
                this.lookupsService.removeLookup(Lookups.Facilitators);
                this.usersStore.add(response);
                this.snackBar.open('User created.');
                return response;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to create User.');
                return EMPTY;
            })
        );
    }

    updateProfile(user: UserProfileInfo): Observable<any> {
        if (user.dateOfBirth) {
            user.dateOfBirth = this.formatDateOfBirth(user.dateOfBirth);
        }
        return this.userDataService
            .updateProfile(user, { observe: 'response' })
            .pipe(
                map(() => {
                    this.snackBar.open('Information updated.');

                    this.usersStore.update(user.id, user);

                    return EMPTY;
                }),
                setLoading(this.usersStore),
                catchError(() => {
                    this.snackBar.open('Failed to update Information.');
                    return EMPTY;
                })
            );
    }
    saveUserInterest(
        userInterest: UserInterest,
        categories: string[],
        languages: string[]
    ): Observable<any> {
        userInterest.categories = categories;
        userInterest.languages = languages;
        return this.userDataService
            .saveUserInterest(userInterest, { observe: 'response' })
            .pipe(
                map(() => {
                    this.snackBar.open('User Interests saved.');
                    return EMPTY;
                }),
                setLoading(this.usersStore),
                catchError(() => {
                    this.snackBar.open('Failed to save User Interests.');
                    return EMPTY;
                })
            );
    }
    getMyUserInterests(): Observable<UserInterest> {
        return this.userDataService.getMyUserInterests().pipe(
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to load user interests.');
                return EMPTY;
            })
        );
    }
    upsert(user: User): Observable<User> {
        const isEditMode = this.usersQuery.getIsEditMode();
        user.dateOfBirth = this.formatDateOfBirth(user.dateOfBirth);
        if (!isEditMode) {
            return this.post(user).pipe(
                map((response: User) => {
                    this.router.navigate([`/admin/users/${response.id}`]);
                    return response;
                })
            );
        } else {
            return this.put(user);
        }
    }

    activateUser(id: string): Observable<User> {
        return this.userDataService.activateUser(id).pipe(
            map((response) => {
                this.usersStore.update(response.id, response);
                this.snackBar.open('User activated.');
                return response;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to activate User.');
                return EMPTY;
            })
        );
    }

    updateChatVisibility(isUserVisibileForChat: boolean): Observable<User> {
        if (isUserVisibileForChat) {
            return this.userDataService.enableChatVisibility().pipe(
                tap((userId) => {
                    this.usersStore.update(userId, (user: User) => {
                        return {
                            ...user,
                            isUserVisibleForChat: true,
                        };
                    });
                    this.snackBar.openWithIcon(
                        'Chat invitations are enabled.',
                        'message-check',
                        'success'
                    );
                }),
                setLoading(this.usersStore),
                catchError(() => {
                    this.snackBar.open('Failed to enable visibility for chat.');
                    return EMPTY;
                })
            );
        } else {
            return this.userDataService.disableChatVisibility().pipe(
                tap((userId) => {
                    this.usersStore.update(userId, (user: User) => {
                        return {
                            ...user,
                            isUserVisibleForChat: false,
                        };
                    });
                    this.snackBar.openWithIcon(
                        'Chat invitations are disabled.',
                        'message-xmark',
                        'info'
                    );
                }),
                setLoading(this.usersStore),
                catchError(() => {
                    this.snackBar.open(
                        'Failed to disable visibility for chat.'
                    );
                    return EMPTY;
                })
            );
        }
    }

    deactivateUser(id: string): Observable<User> {
        return this.userDataService.deactivateUser(id).pipe(
            map((response) => {
                this.usersStore.update(response.id, response);
                this.snackBar.open('User deactivated.');
                return response;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to deactivate User.');
                return EMPTY;
            })
        );
    }

    admitUser(id: string): Observable<User> {
        return this.userDataService.admitUser(id).pipe(
            map((response) => {
                this.usersStore.update(response.id, response);
                this.snackBar.open('User admitted.');
                return response;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to admit User.');
                return EMPTY;
            })
        );
    }

    canDeactivateUser(id: string): Observable<boolean> {
        return this.userDataService.canDeactivateUser(id).pipe(
            catchError(() => {
                this.snackBar.open(
                    'Failed to check if User can be deactivated.'
                );
                return EMPTY;
            })
        );
    }

    setSearch(search: UserSearchQuery): void {
        this.usersStore.update({ search });
    }
    setSelectedRoles(roleIds: string[]): void {
        this.usersStore.update({ selectedRoleIds: roleIds });
    }

    createHostAccount(id: string): Observable<User> {
        return this.userDataService.createHostAccount(id).pipe(
            map((response: User) => {
                if (response != null) {
                    this.usersStore.update(response.id, response);
                }
                this.snackBar.open(
                    'Invitation for a Zoom user account sent successfully. The user has to verify their Zoom account by email before it can be used.'
                );
                return response;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to create Zoom account for user.');
                return EMPTY;
            })
        );
    }

    resendActivationEmail(userId: string): Observable<User> {
        return this.userDataService.resendActivationEmail(userId).pipe(
            tap((response: User) => {
                this.usersStore.update(response.id, response);
                this.snackBar.open('Activation email successfully sent.');
                return response;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to send activation email.');
                return EMPTY;
            })
        );
    }

    createUserForm(): FormGroup {
        const isEditMode: boolean = this.usersQuery.getIsEditMode();

        return this.formBuilder.group(
            {
                id: [],
                firstName: [
                    null,
                    [Validators.required(), Validators.maxLength(50)],
                ],
                lastName: [
                    null,
                    [Validators.required(), Validators.maxLength(100)],
                ],
                email: [
                    null,
                    [
                        Validators.required(),
                        Validators.maxLength(100),
                        Validators.email(),
                    ],
                    Validators.async((value) => this.exists(value)),
                ],
                dateOfBirth: [null],
                dateWithoutDay: [null, [dateWithoutDayValidator()]],
                address1: [null, [Validators.maxLength(150)]],
                address2: [null, [Validators.maxLength(150)]],
                city: [null, [Validators.maxLength(50)]],
                state: [null, [Validators.maxLength(2)]],
                country: [null, [Validators.maxLength(150)]],
                zipCode: [null, [Validators.maxLength(50)]],
                roles: [null, [Validators.required()]],
                groups: [null, Validators.required()],
                primaryGroup: [null, [Validators.required()]],
                languages: [null, [Validators.required()]],
                biography: [
                    null,
                    [
                        Validators.maxLength(500),
                        () => {
                            if (
                                this.permissionsService.hasPermission([
                                    Permissions.Users
                                        .ManageFacilitatorBiography,
                                ])
                            ) {
                                Validators.required();
                            }
                            return null;
                        },
                    ],
                ],
                timezone: [
                    Constants.DEFAULT_TIME_ZONE,
                    [Validators.required()],
                ],
                phones: new FormArray([]),
                categories: [null],
                password: [
                    null,
                    [
                        Validators.required('Required', [
                            {
                                rules: [
                                    {
                                        eval: () => !isEditMode,
                                        controlName: 'password',
                                    },
                                ],
                            },
                        ]),
                        Validators.minLength(
                            8,
                            'The minimum password length is 8 characters.',
                            [
                                {
                                    rules: [
                                        {
                                            eval: () => !isEditMode,
                                            controlName: 'password',
                                        },
                                    ],
                                },
                            ]
                        ),
                    ],
                ],
                confirmPassword: [null],
                status: [true],
                hostAccountStatus: [HostAccountStatus.None],
                attendanceValidationSystems: [null],
                isUserVisibleForChat: [true],
                isSuspended: [false],
            },
            {
                validator: Validators.sameAs(
                    'password',
                    'confirmPassword',
                    'Password',
                    'Confirm Password'
                ),
            }
        );
    }

    UpdateConsumerInformationPageVisibility(id: string): Observable<number> {
        return this.userDataService.UpdateConsumerInformationPageVisibility(id);
    }

    GetConsumerInfoPageVisibility(id: string): Observable<boolean> {
        return this.userDataService.GetConsumerInfoPageVisibility(id);
    }

    getUserForumEnabled(): Observable<boolean> {
        return this.userDataService.getUserForumEnabled();
    }

    UpdateConsumerInformationPageVisibilityIfUserSubmittedInterests(): Observable<number> {
        return this.GetConsumerInfoPageVisibility(this.authService.userId).pipe(
            take(1),
            filter((hasVisitedPage: boolean) => hasVisitedPage === false),
            switchMap(() => this.areUserInterestsSubmitted()),
            filter(
                (areUserInterestsSubmitted: boolean) =>
                    areUserInterestsSubmitted === true
            ),
            switchMap(() =>
                this.UpdateConsumerInformationPageVisibility(
                    this.authService.userId
                )
            )
        );
    }

    areUserInterestsSubmitted(): Observable<boolean> {
        return this.authService.areUserInterestsSubmitted$.pipe(
            map(
                (areUserInterestsSubmitted: boolean) =>
                    areUserInterestsSubmitted
            )
        );
    }

    updateNotificationSettings(
        userNotifSettings: UserNotificationSettings
    ): Observable<User> {
        return this.userDataService
            .updateNotificationSettings(userNotifSettings)
            .pipe(
                map((user: User) => {
                    this.snackBar.open(
                        this.translocoService.translate(
                            'User notifications settings saved.'
                        )
                    );
                    this.usersStore.update(user.id, user);
                    return user;
                }),
                setLoading(this.usersStore),
                catchError(() => {
                    this.snackBar.open(
                        this.translocoService.translate(
                            'Failed to save user notifications settings.'
                        )
                    );
                    return EMPTY;
                })
            );
    }

    getUserSuspendedStatus(userId: string): Observable<boolean> {
        return this.userDataService.getUserSuspendedStatus(userId);
    }

    getIsUserInRoles(userId: string): Observable<boolean> {
        return this.userDataService.getIsUserInRoles(userId);
    }

    private put(user: User): Observable<User> {
        return this.userDataService.put(user).pipe(
            map((response: User) => {
                this.lookupsService.removeLookup(Lookups.GroupAdmins);
                this.lookupsService.removeLookup(Lookups.Facilitators);
                this.notificationsService.removeUserNotificationEventReminders(
                    response.id.toString()
                );
                this.usersStore.update(response.id, response);
                this.snackBar.open('User updated.');
                return response;
            }),
            setLoading(this.usersStore),
            catchError(() => {
                this.snackBar.open('Failed to update User.');
                return EMPTY;
            })
        );
    }

    private formatDateOfBirth(dateOfBirth: any): any {
        if (!dateOfBirth) {
            return null;
        }
        dateOfBirth = moment(dateOfBirth);
        dateOfBirth = dateOfBirth.isValid()
            ? dateOfBirth.toApiStringFormat()
            : null;
        return dateOfBirth;
    }
}
